import React, { useEffect, useRef } from "react";
import { useAnimation, useInView, motion, AnimationProps } from "framer-motion";
import ReactScrollWheelHandler from "react-scroll-wheel-handler";
import { animateScroll } from "react-scroll";
import './ScrollParent.css';

type SectionProp = {
  contents: JSX.Element;
  class: string;
  variants: AnimationProps["variants"];
};

export function ScrollParent(props: {sectionProps: SectionProp[], scrollOptions: any}) {

  const { sectionProps, scrollOptions } = props;
  const refs = sectionProps.map((sp: any) => useRef(null));
  let toRef: NodeJS.Timeout;
  let intRef: NodeJS.Timeout;

  function getTop(ref: any): number {
    if (ref && ref.current) {
      return ref.current.offsetTop;
    }
    return -1;
  }

  const handleScroll = () => {
    clearTimeout(toRef);
    toRef = setTimeout(() => {
      const position = window.pageYOffset;
      const topdiffs = refs.map((r: any) => Math.abs(getTop(r) - position));
      const min = Math.min(...topdiffs);
      const i = topdiffs.indexOf(min);
      animateScroll.scrollTo(getTop(refs[i]), scrollOptions);
    }, 400);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    window.addEventListener('resize', handleScroll, { passive: true });
    intRef = setInterval(handleScroll, 700);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
      clearInterval(intRef);
    };
  }, []);

  function scrollUp() {
    let top = -1;
    for (let index = refs.length - 1; index >= 0; index--) {
      top = getTop(refs[index]);
      if (top < window.pageYOffset - 2) {
        break;
      }
    }
    if (top >= 0) {
      animateScroll.scrollTo(top, scrollOptions);
    }
  }

  function scrollDown() {
    let top = -1;
    for (let index = 0; index < refs.length; index++) {
      top = getTop(refs[index]);
      if (top > window.pageYOffset + 2) {
        break;
      }
    }
    if (top >= 0) {
      animateScroll.scrollTo(top, scrollOptions);
    }
  }

  return (
    <ReactScrollWheelHandler
      upHandler={scrollUp}
      downHandler={scrollDown}>
      <main className="view-section">
        {sectionProps.map((sp: any, i: number) =>
          <ScrollChild key={i} topref={refs[i]} data={sp}>{sp.contents}</ScrollChild>
        )}
      </main>
    </ReactScrollWheelHandler>
  )
}

export function ScrollChild(props: any) {
  const { data, children, topref } = props;
  const controls = useAnimation();
  const localref = useRef(null);
  const inView = useInView(localref);
  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);
  return (
    <section ref={topref} className={data.class}>
      <motion.div
        className='scroll-child-motion'
        ref={localref}
        animate={controls}
        initial="hidden"
        variants={data.variants}
      >{children}</motion.div>
  </section>
  )
}

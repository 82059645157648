import { Link } from 'gatsby';
import React from 'react';
import { ScrollParent } from '../components/ScrollParent';
import './scroll.css';

type Props = {};

function scroll({}: Props) {

  const scrollOptions = {duration: 600, delay: 0, smooth: 'easeInOutQuad'};

  const sectionProps = [
    {
      contents: <div><div>Uno</div><div><Link to="/">Página de Inicio</Link></div></div>,
      class: "scrollable uno",
      variants: {
        visible: { opacity: 1, scale: 1, rotate: 720, transition: { delay: 0.1, duration: 0.5 } },
        hidden: { opacity: 0, scale: 0, rotate: 0 }
      }
    },
    {
      contents: <div>Dos</div>,
      class: "scrollable dos",
      variants: {
        visible: {  scale: [0, 3, 0, 3, 1], transition: { delay: 0.1, duration: 0.5 } },
        hidden: { scale: 0 }
      }
    },
    {
      contents: <div>Tres</div>,
      class: "scrollable tres",
      variants: {
        visible: {
          x: [0, -150, -300, -200, 0, 200, 300, 200, 0, -200, -300, -150, 0],
          y: [0, 0, 0, -200, -300, -200, 0, 200, 300, 200, 0, 0, 0],
          transition: { delay: 0.1, duration: 0.5 }
        },
        hidden: { x: 0, y: 0 }
      }
    },
    {
      contents: <div>Quatro</div>,
      class: "scrollable quatro",
      variants: {
        visible: { rotate: [0, 60, -60, 60, -60, 0], transition: { delay: 0.1, duration: 0.5 } },
        hidden: { rotate: 0 }
      }
    },
    {
      contents: <div>Cinco</div>,
      class: "scrollable cinco",
      variants: {
        visible: { scale: [0, 3, 1], rotate: 360, transition: { delay: 0.1, duration: 0.5 } },
        hidden: { scale: 0, rotate: 0 }
      }
    }
  ];

  return (
    <ScrollParent sectionProps={sectionProps} scrollOptions={scrollOptions}></ScrollParent>
  )
}

export default scroll;
